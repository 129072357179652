
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "0.73.3 (7332207bb10bb5d59d4364699a626aca)",
    buildSeed: 1649139531146,
    "wdosbox.shared.wasm": {
        "size": 1497234,
        "gzSize": 503616
    },
    "wdosbox.shared.js": {
        "size": 158273,
        "gzSize": 39750
    },
    "wdosbox.wasm": {
        "size": 1479102,
        "gzSize": 500062
    },
    "wdosbox.js": {
        "size": 125304,
        "gzSize": 32503
    },
    "wlibzip.wasm": {
        "size": 112813,
        "gzSize": 54476
    },
    "wlibzip.js": {
        "size": 76877,
        "gzSize": 20063
    }
,
};
